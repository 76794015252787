import { ReactComponent as AwardIcon } from "../../assets/images/icons/award-icon.svg";
import VOLAKAS_016_IMG from "../../assets/images/types-of-stone/VOLAKAS_016.jpg";
import MILK_MARBLE_015_IMG from "../../assets/images/types-of-stone/MILK_MARBLE_015.jpg";
import GREEK_MARBLE_014_IMG from "../../assets/images/types-of-stone/GREEK_MARBLE_014.jpg";
import MARBLE_011_IMG from "../../assets/images/types-of-stone/MARBLE_011.jpg";
import MARBLE_012_IMG from "../../assets/images/types-of-stone/MARBLE_012.jpg";
import THASSOS_WHITE_018_IMG from "../../assets/images/types-of-stone/THASSOS_WHITE_018.jpg";
import OCEAN_BLUE_017_IMG from "../../assets/images/types-of-stone/OCEAN_BLUE_017.jpg";
import POLARIS_019_IMG from "../../assets/images/types-of-stone/POLARIS_019.jpg";
import SIVEC_020_IMG from "../../assets/images/types-of-stone/SIVEC_020.jpg";
import SIVEC_021_IMG from "../../assets/images/types-of-stone/SIVEC_021.jpg";
import MARBLE_022_IMG from "../../assets/images/types-of-stone/MARBLE_022.jpg";
import POLARIS_023_IMG from "../../assets/images/types-of-stone/POLARIS_023.jpg";
import MARBLE_024_IMG from "../../assets/images/types-of-stone/MARBLE_024.jpg";
import SIVEC_025_IMG from "../../assets/images/types-of-stone/SIVEC_025.jpg";
import TRAVERTINE_026_IMG from "../../assets/images/types-of-stone/TRAVERTINE_026.jpg";
import TRAVERTINE_027_IMG from "../../assets/images/types-of-stone/TRAVERTINE_027.jpg";
import MARBLE_028_IMG from "../../assets/images/types-of-stone/MARBLE_028.jpg";
import SIVEC_029_IMG from "../../assets/images/types-of-stone/SIVEC_029.jpg";
import ONYX_030_IMG from "../../assets/images/types-of-stone/ONYX_030.jpg";

import MarbleBathroomImg from "../../assets/images/photos/marble-bathroom.png";
import React, { useState } from "react";

function TypesOfStoneSection(props, ref) {
  const stoneItems = [
    {
      id: 0,
      image: VOLAKAS_016_IMG,
      name: "VOLAKAS_016",
    },
    {
      id: 1,
      image: MILK_MARBLE_015_IMG,
      name: "MILK_MARBLE_015",
    },
    {
      id: 2,
      image: GREEK_MARBLE_014_IMG,
      name: "GREEK_MARBLE_014",
    },
    {
      id: 3,
      image: MARBLE_011_IMG,
      name: "MARBLE_011",
    },
    {
      id: 4,
      image: MARBLE_012_IMG,
      name: "MARBLE_012",
    },
    {
      id: 5,
      image: THASSOS_WHITE_018_IMG,
      name: "THASSOS_WHITE_018",
    },
    {
      id: 6,
      image: OCEAN_BLUE_017_IMG,
      name: "OCEAN_BLUE_017",
    },
    {
      id: 7,
      image: POLARIS_019_IMG,
      name: "POLARIS_019",
    },
    {
      id: 8,
      image: SIVEC_020_IMG,
      name: "SIVEC_020",
    },
    {
      id: 9,
      image: SIVEC_021_IMG,
      name: "SIVEC_021",
    },
    {
      id: 10,
      image: MARBLE_022_IMG,
      name: "MARBLE_022",
    },
    {
      id: 11,
      image: POLARIS_023_IMG,
      name: "POLARIS_023",
    },
    {
      id: 12,
      image: MARBLE_024_IMG,
      name: "MARBLE_024",
    },
    {
      id: 13,
      image: SIVEC_025_IMG,
      name: "SIVEC_025",
    },
    {
      id: 14,
      image: TRAVERTINE_026_IMG,
      name: "TRAVERTINE_026",
    },
    {
      id: 15,
      image: TRAVERTINE_027_IMG,
      name: "TRAVERTINE_027",
    },
    {
      id: 16,
      image: MARBLE_028_IMG,
      name: "MARBLE_028",
    },
    {
      id: 17,
      image: SIVEC_029_IMG,
      name: "SIVEC_029",
    },
    {
      id: 18,
      image: ONYX_030_IMG,
      name: "ONYX_030",
    },
  ];

  const [hoveredId, setHoveredId] = useState(0);

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  return (
    <section className="pt-20" ref={ref}>
      <div className="flex flex-col md:flex-row">
        <div className="p-4 lg:p-12 bg-white rounded-t-2xl md:rounded-none md:rounded-l-2xl">
          <div className="flex items-center mb-6">
            <AwardIcon className="w-4 h-4 lg:h-6 lg:w-6" />
            <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
              Натуральний камінь
            </span>
          </div>
          <h2 className="text-h2 uppercase w-max mb-8">Види каменю</h2>
          <div className="w-full flex flex-wrap max-w-[550px]">
            {stoneItems.map((stone) => (
              <span
                onClick={() => handleMouseEnter(stone.id)}
                key={stone.id}
                className={`${
                  hoveredId === stone.id ? "text-custom-yellow" : ""
                } text-custom-black mb-3 cursor-pointer text-editional min-w-[50%]`}
              >
                {stone.name}
              </span>
            ))}
          </div>
        </div>
        <div className="h-96 md:h-[550px] md:w-[70%]">
          <img
            className="rounded-b-2xl w-full h-full object-cover object-center md:rounded-none md:rounded-r-2xl"
            src={stoneItems.find((stone) => stone.id === hoveredId)?.image}
            alt="SantaMarinaImg"
          />
        </div>
      </div>
    </section>
  );
}

export default React.forwardRef(TypesOfStoneSection);
