import { ReactComponent as ArrowDiagonalIcon } from "../../assets/images/icons/arrow-diagonal-icon.svg";

function SubscribeForm() {
  return (
    <div className="flex items-center justify-center md:w-full">
      <div className="flex w-full justify-center">
        <input
          type="text"
          className="px-4 py-2 w-80 bg-inherit border border-solid border-gray-200 rounded-3xl placeholder:text-custom-black placeholder:opacity-70 md:w-full"
          placeholder="E-mail"
        />
        <button className="flex items-center bg-custom-black justify-center w-12 h-12 rounded-full transition-colors duration-500 hover:bg-custom-yellow">
          <ArrowDiagonalIcon className="text-white" />
        </button>
      </div>
    </div>
  );
}

export default SubscribeForm;
