import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo/logo.svg";
import PhoneButton from "../../components/PhoneButton/PhoneButton";
import { motion } from "framer-motion";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.header
      initial={{ top: "-200px" }}
      animate={{
        top: 0,
        background: isScrolled
          ? "rgba(241, 239, 236, .8)"
          : "rgba(241, 239, 236, 0)",
      }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="fixed z-50 left-0 right-0 px-4 flex items-center justify-between py-4 lg:py-6 lg:px-12"
    >
      <span className="hidden lg:flex text-xl font-bold text-custom-black">
        Marble Style
      </span>
      <div className="w-8 h-8 cursor-pointer" onClick={scrollToTop}>
        <Logo className="w-full h-full" />
      </div>
      <PhoneButton />
    </motion.header>
  );
};

export default Header;
