import TextAnimation from "./../TextAnimation/TextAnimation";
import { ReactComponent as UserGroupIcon } from "../../assets/images/icons/user-group-icon.svg";
import SmallStoneImg from "../../assets/images/photos/small-stone.png";
import React from "react";

function AboutUsSection(props, ref) {
  return (
    <section className="pt-20" ref={ref}>
      <div className="flex items-center mb-6">
        <UserGroupIcon className="w-4 h-4 lg:h-6 lg:w-6" />
        <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
          Про нас
        </span>
      </div>
      <div className="mb-24">
        <TextAnimation text="Ми перетворюємо натуральне каміння в шедеври, що віддзеркалюють красу природи та вишуканий смак наших клієнтів. Знаходячись в самому серці столиці, у Києві, ми пропонуємо унікальні вироби, які перетинають мистецтво та функціональність." />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <p className="text-custom-black text-medium mb-8 md:mb-0 md:w-3/12  md:mr-12">
          Ми - масштабне мармурове виробництво, яке охоплює цілий комплекс
          процесів, від видобутку мармурових блоків до їх ретельної обробки.
          Наша компанія охоплює всі етапи та процеси в роботі з мармуром, кожен
          виріб якої - це шедевр, створений природою та вдосконалений руками
          наших досвідчених спеціалістів. Допоможемо вам у втіленні проектів
          будь-якої складності, від класичних форм до сміливих сучасних
          дизайнів. 
        </p>
        <p className="text-custom-black text-medium mb-8 md:mb-0 md:w-3/12">
          Ми маємо широкий асортимент мармуру та володіємо різними видами
          обробки для досягнення потрібного ефекту для вашого виробу. Завдяки
          вашій уяві та нашим спеціалістам, ми перетворимо кожен блок мармуру у
          витвір мистецтва, створений витримувати випробування часом та
          слугувати роками, адже мармур - це символ вічності. А наша робота -
          надати кожному каменю нове життя та закарбувати його вічність у
          інтер'єрі.
        </p>
        <img
          src={SmallStoneImg}
          alt="SmallStoneImg"
          className="w-full m-auto max-w-[500px] md:order-first md:w-3/12 md:mr-[20%]"
        />
      </div>
      <div></div>
    </section>
  );
}

export default React.forwardRef(AboutUsSection);
