import { ReactComponent as ArrowDiagonalBigIcon } from "../../assets/images/icons/arrow-diagonal-big-icon.svg";
import { ReactComponent as DotsGridIcon } from "../../assets/images/icons/dots-grid-icon.svg";
import { motion } from "framer-motion";
import {
  OurProductsItems,
  OurProductsContext,
} from "../../context/OurProductsContext";
import { useContext } from "react";
import React from "react";

function OurServicesSection({ catalogRef }, ref) {
  const { setSelectedItems, selectedItems, clearBreadCrumbs } =
    useContext(OurProductsContext);

  const itemVariants = {
    initial: {
      borderColor: "#B0ACA6",
    },
    hover: {
      borderColor: "#F4AD24",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  const iconVariants = {
    initial: {
      color: "#B0ACA6",
    },
    hover: {
      color: "#F4AD24",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.section
        className="pt-20" ref={ref}
        initial={{ y: "150%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
    >
      <div className="flex items-center mb-6">
        <DotsGridIcon className="w-4 h-4 lg:h-6 lg:w-6" />
        <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
          Галерея наших робіт
        </span>
      </div>
      <div className="border-solid border-t border-custom-gray-100">
        {OurProductsItems.map((item, index) => (
          <motion.div
            key={index}
            className={`${
              selectedItems?.id === item.id ? "!border-custom-yellow" : ""
            } cursor-pointer border-solid border-b py-6 md:flex md:items-center`}
            variants={itemVariants}
            initial="initial"
            whileHover="hover"
            onClick={() => {
              catalogRef.current.scrollIntoView({ behavior: "smooth" });
              clearBreadCrumbs();
              setSelectedItems(item);
            }}
          >
            <h4 className="text-h4 text-custom-black mb-2 md:w-96">
              {item.name}
            </h4>
            <p className="text-medium text-custom-black opacity-70">
              {item.description}
            </p>
            <motion.div
              className="hidden md:flex md:ml-auto"
              variants={iconVariants}
            >
              <ArrowDiagonalBigIcon className="w-6 h-6" />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
}

export default React.forwardRef(OurServicesSection);
