import {motion} from "framer-motion";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
// import RatingCard from "../RatingCard/RatingCard";
import BigStonePhoto from "../../assets/images/photos/big-stone.png";
import FireplacePhoto from "../../assets/images/photos/fireplace.png";
import styles from "./HeroSection.module.css";

function HeroSection(
    {
        ourProjectsRef,
        aboutUs,
        ourServicesRef,
        catalogRef,
        typesOfStoneRef,
        reviewsRef,
    }) {
    const nav = [
        {
            name: "Галерея наших робіт",
            elementId: ourServicesRef,
        },
        {
            name: "Види каменю",
            elementId: typesOfStoneRef,
        },
        {
            name: "Про компанію",
            elementId: aboutUs,
        },
        {
            name: "Проєкти",
            elementId: ourProjectsRef,
        },
        {
            name: "Відгуки",
            elementId: reviewsRef,
        },
    ];
    const handleNavClick = (elementId) => {
        elementId.current.scrollIntoView({behavior: "smooth"});
    };

    return (
        <motion.section
            initial={{y: "150%", opacity: 0.5}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 1, ease: "easeOut"}}
            className="flex-col items-center pt-28 lg:pt-40 flex lg:items-start lg:justify-between lg:flex-row "
        >
            <div className="max-w-screen-sm w-full lg:w-min z-20">
                <h1 className="text-h1 text-custom-black text-center lg:text-start">
                    ВАШ СВІТ УНІКАЛЬНИХ ВИРОБІВ
                </h1>
                <div
                    onClick={() => {
                        reviewsRef.current.scrollIntoView({behavior: "smooth"});
                    }}
                >
                    {/*<RatingCard/>*/}
                </div>
            </div>
            <div
                className={`relative w-full mt-6 lg:mt-0 max-w-screen-sm lg:w-5/12 z-10 ${styles.bigStoneContainer}`}
            >
                <img className="px-0 lg:px-6" src={BigStonePhoto} alt="BigStonePhoto"/>
            </div>
            <div
                className="mt-16 max-w-screen-sm flex flex-col items-center lg:items-start lg:mt-0 w-full lg:w-3/12 z-20">
                {nav.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleNavClick(item.elementId)}
                        className="cursor-pointer text-custom-black text-medium border border-solid border-custom-gray-100 w-full h-12 flex justify-center items-center mb-4 transition-all duration-300 hover:bg-[rgb(244,173,36,0.9)] hover:text-white hover:rounded-[30px]"
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </motion.section>
    );
}

export default HeroSection;
