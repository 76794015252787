// src/context/ProductsContext.js
import React, { useState, createContext, useEffect } from "react";
import INTERIOR_DECORATION_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION.jpg";
import MARBLE_BATHROOM_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATHROOM/MARBLE_BATHROOM_MAIN.jpg";
import MARBLE_BATHROOM_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATHROOM/MARBLE_BATHROOM_1.jpg";
import MARBLE_BATHROOM_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATHROOM/MARBLE_BATHROOM_2.jpg";
import MARBLE_BATHROOM_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATHROOM/MARBLE_BATHROOM_3.jpg";
import MARBLE_BATH_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_MAIN.jpg";
import MARBLE_BATH_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_1.jpg";
import MARBLE_BATH_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_2.jpg";
import MARBLE_BATH_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_3.jpg";
import MARBLE_BATH_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_4.jpg";
import MARBLE_BATH_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_5.jpg";
import MARBLE_BATH_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_6.jpg";
import MARBLE_BATH_7_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_BATH/MARBLE_BATH_7.jpg";
import MARBLE_SINK_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_MAIN.jpg";
import MARBLE_SINK_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_1.jpg";
import MARBLE_SINK_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_2.jpg";
import MARBLE_SINK_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_3.jpg";
import MARBLE_SINK_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_4.jpg";
import MARBLE_SINK_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_5.jpg";
import MARBLE_SINK_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_6.jpg";
import MARBLE_SINK_7_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_SINK/MARBLE_SINK_7.jpg";
import MARBLE_FIREPLACE_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_MAIN.jpg";
import MARBLE_FIREPLACE_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_1.jpg";
import MARBLE_FIREPLACE_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_2.jpg";
import MARBLE_FIREPLACE_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_3.jpg";
import MARBLE_FIREPLACE_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_4.jpg";
import MARBLE_FIREPLACE_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_5.jpg";
import MARBLE_FIREPLACE_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_6.jpg";
import MARBLE_FIREPLACE_7_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_7.jpg";
import MARBLE_FIREPLACE_8_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_8.jpg";
import MARBLE_FIREPLACE_9_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_FIREPLACE/MARBLE_FIREPLACE_9.jpg";
import MARBLE_STAIRS_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_MAIN.jpg";
import MARBLE_STAIRS_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_1.jpg";
import MARBLE_STAIRS_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_2.jpg";
import MARBLE_STAIRS_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_3.jpg";
import MARBLE_STAIRS_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_4.jpg";
import MARBLE_STAIRS_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_5.jpg";
import MARBLE_STAIRS_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_6.jpg";
import MARBLE_STAIRS_7_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_7.jpg";
import MARBLE_STAIRS_8_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_STAIRS/MARBLE_STAIRS_8.jpg";
import MARBLE_KITCHEN_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_MAIN.jpg";
import MARBLE_KITCHEN_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_1.jpg";
import MARBLE_KITCHEN_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_2.jpg";
import MARBLE_KITCHEN_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_3.jpg";
import MARBLE_KITCHEN_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_4.jpg";
import MARBLE_KITCHEN_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_5.jpg";
import MARBLE_KITCHEN_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/MARBLE_KITCHEN/MARBLE_KITCHEN_6.jpg";
import EXTERIOR_DECORATION_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION.jpg";
import MARBLE_HOUSE_MAIN_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_MAIN.jpg";
import MARBLE_HOUSE_1_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_1.jpg";
import MARBLE_HOUSE_2_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_2.jpg";
import MARBLE_HOUSE_3_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_3.jpg";
import MARBLE_HOUSE_4_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_4.jpg";
import MARBLE_HOUSE_5_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_HOUSE/MARBLE_HOUSE_5.jpg";
import MARBLE_COLUMN_MAIN_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_COLUMN/MARBLE_COLUMN_MAIN.jpg";
import MARBLE_COLUMN_1_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_COLUMN/MARBLE_COLUMN_1.jpg";
import MARBLE_COLUMN_2_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_COLUMN/MARBLE_COLUMN_2.jpg";
import MARBLE_COLUMN_3_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_COLUMN/MARBLE_COLUMN_3.jpg";
import LANDSCAPE_DECORATION_MAIN_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/LANDSCAPE_DECORATION/LANDSCAPE_DECORATION_MAIN.jpg";
import LANDSCAPE_DECORATION_1_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/LANDSCAPE_DECORATION/LANDSCAPE_DECORATION_1.jpg";
import LANDSCAPE_DECORATION_2_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/LANDSCAPE_DECORATION/LANDSCAPE_DECORATION_2.jpg";
import LANDSCAPE_DECORATION_3_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/LANDSCAPE_DECORATION/LANDSCAPE_DECORATION_3.jpg";
import MARBLE_STAIRS_EXTERIOR_MAIN_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_STAIRS_EXTERIOR/MARBLE_STAIRS_EXTERIOR_MAIN.jpg";
import MARBLE_STAIRS_EXTERIOR_1_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_STAIRS_EXTERIOR/MARBLE_STAIRS_EXTERIOR_1.jpg";
import MARBLE_STAIRS_EXTERIOR_2_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_STAIRS_EXTERIOR/MARBLE_STAIRS_EXTERIOR_2.jpg";
import MARBLE_STAIRS_EXTERIOR_3_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_STAIRS_EXTERIOR/MARBLE_STAIRS_EXTERIOR_3.jpg";
import MARBLE_STAIRS_EXTERIOR_4_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/MARBLE_STAIRS_EXTERIOR/MARBLE_STAIRS_EXTERIOR_4.jpg";
import CHURCH_THEME_MAIN_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_MAIN.jpg";
import CHURCH_THEME_1_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_1.jpg";
import CHURCH_THEME_2_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_2.jpg";
import CHURCH_THEME_3_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_3.jpg";
import CHURCH_THEME_4_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_4.jpg";
import CHURCH_THEME_5_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_5.jpg";
import CHURCH_THEME_6_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_6.jpg";
import CHURCH_THEME_7_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_7.jpg";
import OTHER_MAIN_IMG from "../assets/images/catalog/OTHER/OTHER_MAIN.jpg";
import OTHER_1_IMG from "../assets/images/catalog/OTHER/OTHER_1.jpg";
import OTHER_2_IMG from "../assets/images/catalog/OTHER/OTHER_2.jpg";
import OTHER_3_IMG from "../assets/images/catalog/OTHER/OTHER_3.jpg";
import OTHER_4_IMG from "../assets/images/catalog/OTHER/OTHER_4.jpg";
import OTHER_5_IMG from "../assets/images/catalog/OTHER/OTHER_5.jpg";
import OTHER_6_IMG from "../assets/images/catalog/OTHER/OTHER_6.jpg";
import OTHER_7_IMG from "../assets/images/catalog/OTHER/OTHER_7.jpg";
import OTHER_8_IMG from "../assets/images/catalog/OTHER/OTHER_8.jpg";
import OTHER_9_IMG from "../assets/images/catalog/OTHER/OTHER_9.jpg";
import OTHER_10_IMG from "../assets/images/catalog/OTHER/OTHER_10.jpg";
import OTHER_11_IMG from "../assets/images/catalog/OTHER/OTHER_11.jpg";
import OTHER_12_IMG from "../assets/images/catalog/OTHER/OTHER_12.jpg";
import OTHER_13_IMG from "../assets/images/catalog/OTHER/OTHER_13.jpg";

const generateId = () => {
  return Math.floor(100000000 + Math.random() * 900000000);
};

export const OurProductsItems = [
  {
    id: generateId(),
    name: "Внутрішнє оздоблення",
    image: INTERIOR_DECORATION_IMG,
    description:
      "Ванни, раковини, сходи, підвіконня, стіни, каміни, колони, стільниці…",
    items: [
      {
        id: generateId(),
        name: "Мармурова ванна кімната",
        image: MARBLE_BATHROOM_MAIN_IMG,
        items: [
          { image: MARBLE_BATHROOM_1_IMG },
          { image: MARBLE_BATHROOM_2_IMG },
          { image: MARBLE_BATHROOM_3_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурова ванна",
        image: MARBLE_BATH_MAIN_IMG,
        items: [
          { image: MARBLE_BATH_1_IMG },
          { image: MARBLE_BATH_2_IMG },
          { image: MARBLE_BATH_3_IMG },
          { image: MARBLE_BATH_4_IMG },
          { image: MARBLE_BATH_5_IMG },
          { image: MARBLE_BATH_6_IMG },
          { image: MARBLE_BATH_7_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурова раковина",
        image: MARBLE_SINK_MAIN_IMG,
        items: [
          { image: MARBLE_SINK_1_IMG },
          { image: MARBLE_SINK_2_IMG },
          { image: MARBLE_SINK_3_IMG },
          { image: MARBLE_SINK_4_IMG },
          { image: MARBLE_SINK_5_IMG },
          { image: MARBLE_SINK_6_IMG },
          { image: MARBLE_SINK_7_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармуровий камін",
        image: MARBLE_FIREPLACE_MAIN_IMG,
        items: [
          { image: MARBLE_FIREPLACE_1_IMG },
          { image: MARBLE_FIREPLACE_2_IMG },
          { image: MARBLE_FIREPLACE_3_IMG },
          { image: MARBLE_FIREPLACE_4_IMG },
          { image: MARBLE_FIREPLACE_5_IMG },
          { image: MARBLE_FIREPLACE_6_IMG },
          { image: MARBLE_FIREPLACE_7_IMG },
          { image: MARBLE_FIREPLACE_8_IMG },
          { image: MARBLE_FIREPLACE_9_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурові сходи",
        image: MARBLE_STAIRS_MAIN_IMG,
        items: [
          { image: MARBLE_STAIRS_1_IMG },
          { image: MARBLE_STAIRS_2_IMG },
          { image: MARBLE_STAIRS_3_IMG },
          { image: MARBLE_STAIRS_4_IMG },
          { image: MARBLE_STAIRS_5_IMG },
          { image: MARBLE_STAIRS_6_IMG },
          { image: MARBLE_STAIRS_7_IMG },
          { image: MARBLE_STAIRS_8_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурова кухня",
        image: MARBLE_KITCHEN_MAIN_IMG,
        items: [
          { image: MARBLE_KITCHEN_1_IMG },
          { image: MARBLE_KITCHEN_2_IMG },
          { image: MARBLE_KITCHEN_3_IMG },
          { image: MARBLE_KITCHEN_4_IMG },
          { image: MARBLE_KITCHEN_5_IMG },
          { image: MARBLE_KITCHEN_6_IMG },
        ],
      },
    ],
  },
  {
    id: generateId(),
    name: "Зовнішнє оздоблення",
    image: EXTERIOR_DECORATION_IMG,
    description:
      "Будинки, фасади, альтанки, колони, скульптури, сходи, фонтани…",
    items: [
      {
        id: generateId(),
        name: "Мармуровий будинок",
        image: MARBLE_HOUSE_MAIN_IMG,
        items: [
          { image: MARBLE_HOUSE_1_IMG },
          { image: MARBLE_HOUSE_2_IMG },
          { image: MARBLE_HOUSE_3_IMG },
          { image: MARBLE_HOUSE_4_IMG },
          { image: MARBLE_HOUSE_5_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурова колона",
        image: MARBLE_COLUMN_MAIN_IMG,
        items: [
          { image: MARBLE_COLUMN_1_IMG },
          { image: MARBLE_COLUMN_2_IMG },
          { image: MARBLE_COLUMN_3_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Оздоблення ландшафту",
        image: LANDSCAPE_DECORATION_MAIN_IMG,
        items: [
          { image: LANDSCAPE_DECORATION_1_IMG },
          { image: LANDSCAPE_DECORATION_2_IMG },
          { image: LANDSCAPE_DECORATION_3_IMG },
        ],
      },
      {
        id: generateId(),
        name: "Мармурові сходи",
        image: MARBLE_STAIRS_EXTERIOR_MAIN_IMG,
        items: [
          { image: MARBLE_STAIRS_EXTERIOR_1_IMG },
          { image: MARBLE_STAIRS_EXTERIOR_2_IMG },
          { image: MARBLE_STAIRS_EXTERIOR_3_IMG },
          { image: MARBLE_STAIRS_EXTERIOR_4_IMG },
        ],
      },
    ],
  },
  {
    id: generateId(),
    name: "Ритуальні вироби",
    image: CHURCH_THEME_MAIN_IMG,
    description: "пам'ятники, склепи, стели, надгробні плити…",
    items: [
      { image: CHURCH_THEME_1_IMG },
      { image: CHURCH_THEME_2_IMG },
      { image: CHURCH_THEME_3_IMG },
      { image: CHURCH_THEME_4_IMG },
      { image: CHURCH_THEME_5_IMG },
      { image: CHURCH_THEME_6_IMG },
      { image: CHURCH_THEME_7_IMG },
    ],
  },
  {
    id: generateId(), // допрацювати, добавити картинки.
    name: "Ландшафт",
    image: OTHER_11_IMG,
    description: "скульптури, фонтани, огорожі, декоративні елементи…",
    items: [
      { image: OTHER_9_IMG },
      { image: OTHER_10_IMG },
      { image: OTHER_11_IMG },
      { image: OTHER_12_IMG },
      { image: OTHER_13_IMG },
    ],
  },
  {
    id: generateId(),
    name: "Мармурові сляби/блоки",
    image: OTHER_MAIN_IMG,
    description: "сляби, блоки, галька, плитка…",
    items: [
      { image: OTHER_1_IMG },
      { image: OTHER_2_IMG },
      { image: OTHER_3_IMG },
      { image: OTHER_4_IMG },
      { image: OTHER_5_IMG },
      { image: OTHER_6_IMG },
      { image: OTHER_7_IMG },
      { image: OTHER_8_IMG },
    ],
  },
];

export const OurProductsContext = createContext();

export const OurProductsProvider = ({ children }) => {
  const [allItems] = useState(OurProductsItems);
  const [filteredOrSelectedItems, setFilteredOrSelectedItems] =
    useState(allItems);
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    setFilteredOrSelectedItems(allItems);
  }, [allItems]);

  const setSelectedItems = (item) => {
    if (item) {
      setFilteredOrSelectedItems(item.items);
      setBreadCrumbs((prevBreadCrumbs) => [...prevBreadCrumbs, item.name]);
    } else {
      setFilteredOrSelectedItems(allItems);
      clearBreadCrumbs();
    }
  };

  const handleClickBack = () => {
    if (breadCrumbs.length === 0) return;

    const newBreadCrumbs = [...breadCrumbs];
    newBreadCrumbs.pop();

    let prevItems = allItems;

    newBreadCrumbs.forEach((crumb) => {
      const foundItem = prevItems.find((item) => item.name === crumb);
      if (foundItem) {
        prevItems = foundItem.items;
      }
    });

    setFilteredOrSelectedItems(prevItems);
    setBreadCrumbs(newBreadCrumbs);
  };

  const clearBreadCrumbs = () => {
    setBreadCrumbs([]);
  };

  return (
    <OurProductsContext.Provider
      value={{
        selectedItems: filteredOrSelectedItems,
        setSelectedItems,
        breadCrumbs,
        handleClickBack,
        clearBreadCrumbs,
      }}
    >
      {children}
    </OurProductsContext.Provider>
  );
};
