import { ReactComponent as ArrowToTopIcon } from "../../assets/images/icons/arrow-top-icon.svg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const ArrowToTop = () => {
  const isMobile = window.innerWidth <= 768;

  const variants = {
    hover: {
      backgroundColor: "#2D2B28",
      borderColor: "none",
      transition: {
        duration: isMobile ? 0.2 : 0.4,
        ease: "easeInOut",
      },
    },
    initial: {
      borderColor: "#B0ACA6",
    },
  };

  const variantsTwo = {
    hover: {
      top: isMobile ? "20%" : "25%",
      color: "#F1EFEC",
      transition: {
        duration: isMobile ? 0.2 : 0.4,
        ease: "easeInOut",
      },
    },
    initial: {
      top: isMobile ? "30%" : "35%",
      left: isMobile ? "31%" : "34%",
      color: "#B0ACA6",
    },
  };

  return (
    <motion.div
      className="relative cursor-pointer flex rounded-full w-16 h-16 md:w-20 md:h-20 border border-solid"
      initial="initial"
      whileHover={!isMobile ? "hover" : null}
      whileTap={isMobile ? "hover" : null}
      variants={variants}
    >
      <motion.div className="absolute" variants={variantsTwo}>
        <ArrowToTopIcon />
      </motion.div>
    </motion.div>
  );
};

export default ArrowToTop;
