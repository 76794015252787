import React from "react";
import Slider from "react-slick";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import { ReactComponent as HeartIcon } from "../../assets/images/icons/heart-icon.svg";
import MarbleBathroomImg from "../../assets/images/photos/marble-bathroom.png";
import MarbleSinksroomImg from "../../assets/images/photos/marble-sinks.png";
import LandscapingImg from "../../assets/images/photos/landscaping.png";
import ExteriorDecorationImg from "../../assets/images/photos/exterior-decoration.png";
import MarbleBathImg from "../../assets/images/photos/marble-bath.png";
import MarbleFireplaceImg from "../../assets/images/photos/marble-fireplace.png";
import MarbleStairsImg from "../../assets/images/photos/marble-stairs.png";
import MarbleCountertopsImg from "../../assets/images/photos/marble-countertops.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollResponsiveImage from "../ScrollResponsiveImage/ScrollResponsiveImage";

function OurProjectsSection(props, ref) {
  const slider = React.useRef(null);
  // const [activeSlide, setActiveSlide] = useState(0);

  const sliderItems = [
    {
      title: "Мармурова Ванна кімната",
      description:
        "Мармурова ванна кімната — це поєднання краси природного каменю з практичністю та довговічністю. Водостійкість мармуру робить його ідеальним вибором для ванної кімнати, де підвищена вологість є нормою. Мармурова ванна кімната — це інвестиція в безчасову красу та довговічність, яка перетворює щоденні ритуали на справжнє задоволення",
      img: MarbleBathroomImg,
    },
    {
      title: "Мармурові раковини",
      description:
        "Використання мармурових раковин у інтер'єрі виправдовує себе не тільки на рівні естетики, але й має значні переваги через свою гігієнічність та практичність у щоденному використанні. Поверхні легко очищаються від забруднень, що дозволяє без зайвих зусиль у догляді зберегти мармуру свій первісний зовнішній вигляд протягом багатьох років.",
      img: MarbleSinksroomImg,
    },
    {
      title: "Ландшафтний дизайн",
      description:
        "Використання мармуру в ландшафтному дизайні включає широкий спектр виробів — від бесідок, доріжок і скульптур до декоративних стін та огорож. Мармурові вироби відрізняються стійкістю до погодних умов і не вимагають значного догляду.",
      img: LandscapingImg,
    },
    {
      title: "Зовнішнє оздоблення",
      description:
        "Мармур — надзвичайно міцний і довговічний матеріал, здатний витримувати суворі погодні умови, що робить його надійним матеріалом для зовнішнього оздоблення. Цей камінь має хороші теплоізоляційні властивості, допомагаючи підтримувати стабільну температуру всередині будівлі.",
      img: ExteriorDecorationImg,
    },
    {
      title: "Мармурова Ванна",
      description:
        "Ванна з мармуру має свої переваги серед інших матеріалів завдяки ефективному розподілу тепла по своїй поверхні. Це допомагає уникнути холодних зон і накопичувати тепло, що робить купання в мармуровій ванні особливо комфортним.",
      img: MarbleBathImg,
    },
    {
      title: "Камін з мармуру",
      description:
        "Камін з мармуру — тепле серце вашого будинку. Ідеальний вибір для тих, хто віддає перевагу красі натурального каменю та прагне створити атмосферу тепла та затишку в своїй оселі. Завдяки природним властивостям мармуру, камін здатний витримувати високі температури та ефективно розподіляти тепло для підтримки оптимальної температури в кімнаті.",
      img: MarbleFireplaceImg,
    },
    {
      title: "Мармурові сходи",
      description:
        "Мармурові сходи в інтер'єрі будинку не просто забезпечують функціональний перехід між поверхами, але й стають справжньою прикрасою. Матеріал, відомий своєю міцністю та стійкістю до механічних пошкоджень.",
      img: MarbleStairsImg,
    },
    {
      title: "Мармурові стільниці",
      description:
        "Використовуючи мармур для стільниць, підлог або задніх панелей, можна створити простір, що відзначається красою, міцністю та легкістю в догляді. Мармурові поверхні ідеально підходять для кухні, об'єднуючи функціональність з вишуканістю.",
      img: MarbleCountertopsImg,
    },
  ];

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-6 right-2/4 px-1 md:bottom-12 md:left-12">
        <NavigationArrow turnOverLeft={true} onClick={onClick} />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-6 left-2/4 px-1 md:bottom-12 md:left-36">
        <NavigationArrow onClick={onClick} />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // beforeChange: (current, next) => {
    //   setActiveSlide(next);
    // },
  };

  return (
    <section
      ref={ref}
      className="pt-16"
    >
      <div className="relative bg-custom-black rounded-2xl">
        <div className="slider-container">
          <Slider ref={slider} {...settings} className="flex">
            {sliderItems &&
              sliderItems.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col max-h-full md:flex-row-reverse xl:max-h-[700px]">
                    <div className="md:w-3/6">
                      <ScrollResponsiveImage
                        className="rounded-t-2xl max-h-[500px] md:max-h-full md:rounded-none md:rounded-r-2xl w-full h-full"
                        src={item.img}
                        alt="MarbleBathroomImg"
                      />
                      {/* <img
                      className="rounded-t-2xl max-h-[500px] md:max-h-full md:rounded-none md:rounded-r-2xl w-full h-full"
                      src={item.img}
                      alt="MarbleBathroomImg"
                    /> */}
                    </div>
                    <div className="py-6 px-4 md:w-3/6 md:p-12">
                      <div className="flex items-center mb-6">
                        <HeartIcon className="w-4 h-4 lg:h-6 lg:w-6" />
                        <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
                          Проєкти
                        </span>
                      </div>
                      <h2 className="text-h2 uppercase text-custom-offwhite mb-6 md:max-w-[500px]">
                        {item.title}
                      </h2>
                      <p className="text-medium text-custom-offwhite opacity-70 mb-16 md:max-w-[500px]">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default React.forwardRef(OurProjectsSection);
