import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

const TextAnimation = ({ text }) => {
  const words = text.split(" ");
  const [visibleIndex, setVisibleIndex] = useState(-1);
  const observerRef = useRef(null);
  const timeoutIds = useRef([]); // Для зберігання ідентифікаторів таймерів

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            words.forEach((_, index) => {
              const id = setTimeout(() => {
                setVisibleIndex(index);
              }, index * 100);
              timeoutIds.current.push(id); // Зберігаємо ідентифікатор
            });
          } else {
            // Якщо текст виходить з області видимості, очищаємо всі таймери
            timeoutIds.current.forEach(clearTimeout);
            timeoutIds.current = []; // Очищаємо масив ідентифікаторів
            setVisibleIndex(-1); // Скидаємо індекс видимого слова
          }
        });
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      observer.disconnect();
      timeoutIds.current.forEach(clearTimeout); // Очищаємо всі таймери при розмонтовуванні
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <h3 ref={observerRef} className="flex flex-wrap text-h3 max-w-[1000px]">
      {words.map((word, index) => (
        <motion.span
          key={index}
          initial={{ color: "#B0ACA6" }}
          animate={{ color: index <= visibleIndex ? "#2D2B28" : "#B0ACA6" }}
          transition={{ duration: 0.5 }}
          style={{ marginRight: "8px" }}
        >
          {word}
        </motion.span>
      ))}
    </h3>
  );
};

export default TextAnimation;
