import { ReactComponent as NavigationArrowIcon } from "../../assets/images/icons/navigation-arrow-icon.svg";
import { motion } from "framer-motion";

// accepts two parameters: onLightBg - for a button on a black background, turnOverLeft - for a button directed to the left
const NavigationArrow = ({ onLightBg, turnOverLeft, onClick }) => {
  const isDesktop = window.innerWidth > 768;

  const variants = {
    hover: {
      backgroundColor: onLightBg ? "#2D2B28" : "#F1EFEC",
      borderColor: "none",
      transition: {
        duration: isDesktop ? 0.4 : 0.2,
        ease: "easeInOut",
      },
    },
    initial: {
      borderColor: onLightBg ? "#2D2B28" : "#F1EFEC4D",
    },
  };

  const variantsTwo = {
    hover: {
      color: onLightBg ? "#F1EFEC" : "#2D2B28",
      transition: {
        duration: isDesktop ? 0.4 : 0.2,
        ease: "easeInOut",
      },
    },
    initial: {
      color: onLightBg ? "#2D2B28" : "#F1EFEC",
    },
  };
  return (
    <motion.div
      className={`cursor-pointer flex rounded-full w-20 h-10 border border-solid ${
        turnOverLeft ? "scale-x-[-1]" : ""
      }`}
      initial="initial"
      whileHover={isDesktop ? "hover" : null}
      whileTap={!isDesktop ? "hover" : null}
      variants={variants}
      onClick={onClick}
    >
      <motion.div
        className="h-full w-full flex items-center justify-center"
        variants={variantsTwo}
      >
        <NavigationArrowIcon />
      </motion.div>
    </motion.div>
  );
};

export default NavigationArrow;
