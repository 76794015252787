import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import { ReactComponent as MenuIcon } from "../../assets/images/icons/menu-icon.svg";
import { ReactComponent as StarIcon } from "../../assets/images/icons/star-icon.svg";
import DirectorOfWhiteAgency from "../../assets/images/photos/user.png";

function daysAgo(timestamp) {
  const now = new Date();
  const date = new Date(timestamp * 1000);
  const diff = now - date;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  return days;
}

const PLACE_ID = 'ChIJu1m5PBbP1EARq4fpZHojFPw';
const KEY_ID = 'AIzaSyCyFIMUonvUAo0WxBxaRNC93KCPLVBvkWc'

function ReviewsSection(props, ref) {
  const slider = React.useRef(null);
  const [reviews, setReviews] = useState([]);

  const sliderItems = [
    {
      title: "Мармурова Ванна кімната",
      description:
        "“Я дуже задоволений своєю покупкою від Marble Style. Виріб, який я отримав, перевершив усі мої очікування. Камінь виглядає неймовірно елегантно та натурально, а майстерність виготовлення просто вражає. ”",
    },
    {
      title: "Мармурова Ванна кімната",
      description:
        "”Мармурова ванна кімната — це поєднання краси природного каменю з практичністю та довговічністю. Водостійкість мармуру робить його ідеальним вибором для ванної кімнати, де підвищена вологість є нормою.”",
    },
  ];

    useEffect(() => {
        const loadGoogleMapsApi = () => {
            return new Promise((resolve, reject) => {
                if (window.google && window.google.maps) {
                    resolve(window.google);
                } else {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyFIMUonvUAo0WxBxaRNC93KCPLVBvkWc&libraries=places`;
                    script.async = true;
                    script.onload = () => resolve(window.google);
                    script.onerror = () => reject(new Error('Failed to load Google Maps API'));
                    document.head.appendChild(script);
                }
            });
        };

        const fetchReviews = () => {
            loadGoogleMapsApi()
                .then((google) => {
                    const service = new google.maps.places.PlacesService(document.createElement('div'));
                    const request = {
                        placeId: PLACE_ID,
                        fields: ['reviews'],
                    };
                    service.getDetails(request, (place, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            const sortArray = place.reviews?.sort((a, b) => b.time - a.time );
                            setReviews(sortArray || []);
                        } else {
                        }
                    });
                })
                .catch((err) => {
                });
        };

        fetchReviews();
    }, []);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-6 right-2/4 px-1 md:bottom-12 md:left-12">
        <NavigationArrow turnOverLeft={true} onClick={onClick} />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-6 left-2/4 px-1 md:bottom-12 md:left-36">
        <NavigationArrow onClick={onClick} />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
      reviews && reviews.length > 0 && (
          <section className="pt-16 " ref={ref}>
            <div className="slider-container relative bg-custom-black rounded-2xl">
              <Slider ref={slider} {...settings} className="flex">
                {reviews &&
                    reviews.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="flex flex-col max-h-full md:flex-row">
                            <div className="py-6 px-4 md:w-3/6 md:p-12">
                              <div className="flex items-center mb-6">
                                <MenuIcon className="w-4 h-4 lg:h-6 lg:w-6" />
                                <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
                        Відгуки
                      </span>
                              </div>
                              <h2 className="text-h2 uppercase text-custom-offwhite md:mb-6 md:max-w-[500px]">
                                Що говорять наші клієнти
                              </h2>
                            </div>
                            <div className="flex flex-col justify-between py-2 px-4 md:w-3/6 md:p-12">
                              <div className="bg-custom-offwhite rounded-2xl flex items-center py-2 px-6 w-max">
                      <span className="text-medium font-bold text-custom-black mr-1">
                        {item.rating}
                      </span>
                                <StarIcon className="w-4 h-4 text-custom-black lg:h-6 lg:w-6" />
                              </div>
                              <p className="text-h4 text-custom-offwhite my-10">
                                {item.text}
                              </p>
                              <div className="flex mb-24 md:mb-0">
                                <img
                                    className="w-14 h-14"
                                    src={item.profile_photo_url || DirectorOfWhiteAgency}
                                    alt="profile"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = DirectorOfWhiteAgency;
                                    }}
                                />
                                <div className="flex flex-col justify-between py-2 pl-4 md:pl-6">
                                  <h5 className="text-custom-offwhite text-editional_bold">
                                    {item.author_name}
                                  </h5>
                                  <span className="text-custom-offwhite text-editional opacity-70">
                           {daysAgo(item.time) >= 1 ? `${daysAgo(item.time)} днів тому` : 'Сьогодні'}
                        </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                    ))}
              </Slider>
            </div>
          </section>
      )
  );
}

export default React.forwardRef(ReviewsSection);
