import AboutUsSection from "./components/AboutUsSection/AboutUsSection";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeroSection from "./components/HeroSection/HeroSection";
import CatalogSection from "./components/CatalogSection/CatalogSection";
import OurProjectsSection from "./components/OurProjectsSection/OurProjectsSection";
import OurServicesSection from "./components/OurServicesSection/OurServicesSection";
import PhoneButton from "./components/PhoneButton/PhoneButton";
import ReviewsSection from "./components/ReviewsSection/ReviewsSection";
import TypesOfStoneSection from "./components/TypesOfStoneSection/TypesOfStoneSection";
import { useEffect, useRef } from "react";
import { OurProductsProvider } from "./context/OurProductsContext";
import ArrowToTop from "./components/ArrowToTop/ArrowToTop";

function App() {
    useEffect(() => {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
            window.scrollTo(0, 0);
        }
    }, []);
    const ourProjectsRef = useRef(null);
    const aboutUs = useRef(null);
    const ourServicesRef = useRef(null);
    const catalogRef = useRef(null);
    const typesOfStoneRef = useRef(null);
    const reviewsRef = useRef(null);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="bg-custom-offwhite-opacity px-4 lg:px-12">
            <Header/>
            <HeroSection
                ourProjectsRef={ourProjectsRef}
                aboutUs={aboutUs}
                ourServicesRef={ourServicesRef}
                catalogRef={catalogRef}
                typesOfStoneRef={typesOfStoneRef}
                reviewsRef={reviewsRef}/>
            <OurProductsProvider>
                <OurServicesSection ref={ourServicesRef} catalogRef={catalogRef}/>
                <CatalogSection ref={catalogRef}/>
            </OurProductsProvider>
            <TypesOfStoneSection ref={typesOfStoneRef}/>
            <AboutUsSection ref={aboutUs}/>
            <OurProjectsSection ref={ourProjectsRef}/>
            <ReviewsSection ref={reviewsRef}/>
            <section className="pt-20 py-36 md:py-36 flex flex-col items-center relative">
                <h2 className="text-h2 uppercase text-custom-black text-center mb-6 max-w-[840px] md:mb-8">
                    Ваш простір - це ваш стиль та індивідуальність.
                </h2>
                <PhoneButton/>
                <div className="absolute right-0 bottom-4" onClick={scrollToTop}>
                    <ArrowToTop/>
                </div>
            </section>
            <Footer
                ourProjectsRef={ourProjectsRef}
                aboutUs={aboutUs}
                ourServicesRef={ourServicesRef}
                catalogRef={catalogRef}
                typesOfStoneRef={typesOfStoneRef}
                reviewsRef={reviewsRef}
            />
        </div>
    );
}

window.onload = function () {
    window.scrollTo(0, 0);
};

export default App;
