import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

const ScrollResponsiveImage = ({ src, alt = "", className }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const imageRef = useRef(null);

  const checkIfInView = () => {
    if (!imageRef.current) return false;
    const rect = imageRef.current.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom >= 0;
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsZoomed(checkIfInView());
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`${className} overflow-hidden flex justify-center items-center h-full`}
    >
      <motion.img
        className="w-full h-full object-cover object-center"
        ref={imageRef}
        src={src}
        alt={alt}
        initial={{ scale: 1.3 }}
        animate={{ scale: isZoomed ? 1 : 1.3 }}
        transition={{ duration: isZoomed ? 1.5 : 0, ease: "easeInOut" }}
      />
    </div>
  );
};

export default ScrollResponsiveImage;
