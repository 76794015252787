import {ReactComponent as PhoneIcon} from "../../assets/images/icons/phone-icon.svg";
import {motion} from "framer-motion";

const PhoneButton = () => {
    const isDesktop = window.innerWidth > 768;

    const variants = {
        hover: {
            width: "100%",
            transition: {
                duration: 0.1,
                ease: "easeInOut",
            },
        },
    };

    const variantsTwo = {
        hover: {
            color: "#F1EFEC",
            transition: {
                duration: 0.1,
                ease: "easeInOut",
                delay: 0,
            },
        },
    };

    return (
        <div>
            {!isDesktop && <motion.a
                href="tel:+380932444424"
                className="flex h-12 w-44 relative"
                initial="initial"
                whileHover={isDesktop ? "hover" : null}
                whileTap={!isDesktop ? "hover" : null}
            >
                <motion.div
                    className="relative w-12 h-12 bg-custom-black rounded-full"
                    variants={variants}
                >
                    <div className="absolute text-custom-offwhite w-12 h-12 flex justify-center items-center z-20">
                        <PhoneIcon/>
                    </div>
                </motion.div>
                <motion.div
                    className="absolute text-custom-black text-btn_small z-10 w-32 h-12 rounded-full border border-solid border-custom-black flex justify-center items-center right-0"
                    variants={variantsTwo}
                >
                    Зв'язатись
                </motion.div>
            </motion.a>}
            {
                isDesktop && <a className="text-xl font-bold" href="tel:+380932444424">+38 (093) 24-44-424</a>
            }
        </div>

    );
};

export default PhoneButton;
